import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function CardBooster({ provider, signedInAddress }) {

  const SUCCESS_MSG = "Success! Booster Card has been crafted and submitted to the network. Please wait for confirmation on the blockchain.";

  const [toAddress, setToAddress] = useState("");
  const [amount, setAmount] = useState("0");
  const [boostAmount, setBoostAmount] = useState("0");

  async function craftBoosterCard(provider, toAddress, amount, boostAmount) {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let signed = await contract.connect(signer);
    let boostCraft_result;
    try {
      await signed.craftBoosterCard(toAddress, amount, boostAmount);
      boostCraft_result = SUCCESS_MSG;
    } catch (error) {
      boostCraft_result = error;
    }
    alert(boostCraft_result);
    return boostCraft_result;
  }
  function craftCards() {
    if(amount === "0") {
      alert("You cannot craft zero cards!")
    } else if(boostAmount === "0") {
      alert("Booster Card must have a boost amount!")
    } else {
      craftBoosterCard(provider, toAddress, amount, boostAmount);
    }
  }

  return (
    <div className="crafter-single">
      <Card>
        <Card.Body>
          <Card.Title>Booster Crafter</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">CREATE BOOSTER CARDS</Card.Subtitle>
          <Card.Text className="card-crafter-description">Creates single use Booster Cards that have the ability to reactivate or increase the redeem amount of collection cards and all rewards associated with them. Booster Cards will burn once redeemed.</Card.Text>

          <Form>
            <Form.Group>
              <Form.Row>
                <Form.Label column="sm" lg={4}>Owner Address:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="0x0" onChange={e => setToAddress(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Amount to Craft:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="Total cards to craft" onChange={e => setAmount(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Boost Amount:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 5 (5 additional redeems)" onChange={e => setBoostAmount(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
            </Form.Group>

            <div className = "centered-submit-buttons">
              <Button variant="success" onClick={craftCards}>CRAFT BOOSTER CARDS</Button>
            </div>
          </Form>


        </Card.Body>
      </Card>
    </div>
  )
}
