import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function CardIncreaser({ provider, signedInAddress }) {

  const SUCCESS_MSG = "Success! Card Increase has been submitted to the network. Please wait for confirmation on the blockchain.";

  const [toAddress, setToAddress] = useState("0x0");
  const [amount, setAmount] = useState("0");
  const [cardToIncrease, setCardToIncrease] = useState("0");

  async function increaseBulkBoosterCard(provider, toAddress, amount, cardID) {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let signed = await contract.connect(signer);
    let increaseBulk_result;
    try {
      await signed.increaseBulkBoosterCard(toAddress, amount, cardID);
      increaseBulk_result = SUCCESS_MSG;
    } catch (error) {
      increaseBulk_result = error;
    }
    alert(increaseBulk_result);
    return increaseBulk_result;
  }

  function increaseCards() {
    if(cardToIncrease === "0") {
      alert("Enter a valid card ID!")
    } else if(amount === "0") {
      alert("Cannot increase card by zero!")
    } else {
      increaseBulkBoosterCard(provider, toAddress, amount, cardToIncrease);
    }
  }

  return (
    <div className="crafter-single">
      <Card>
        <Card.Body>
          <Card.Title>Increaser Crafter</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">INCREASE CARD QUANTITY</Card.Subtitle>
          <Card.Text className="card-crafter-description">Increase the quantity of a specific cardID. Only Booster Cards and Bulk Cards can be increased.</Card.Text>

          <Form>
            <Form.Group>
            <Form.Row>
              <Form.Label column="sm" lg={4}>Owner Address:</Form.Label>
              <Col>
                <Form.Control size="sm" type="text" placeholder="0x0" onChange={e => setToAddress(e.target.value)} />
              </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>CardID:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="ID of card to increase" onChange={e => setCardToIncrease(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Amount of Cards:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="amount of additional cards to craft" onChange={e => setAmount(e.target.value)} />
                </Col>
              </Form.Row>
            </Form.Group>

            <div className = "centered-submit-buttons">
              <Button variant="success" onClick={increaseCards}>INCREASE CARDS</Button>
            </div>

          </Form>

        </Card.Body>
      </Card>
    </div>
  )
}
