import React, { useState, useEffect } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Spinner from 'react-bootstrap/Spinner';
import RedeemButton from './redeembutton';

import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function SingleCard({ provider, currentNetwork, correctNetwork, signedInAddress, cardID }) {

  const [cardData, setCardData] = useState("0");
  const [fetchingCardData, setFetchingCardData] = useState(false);
  const [cardMetaData, setCardMetaData] = useState("0");
  const [fetchingCardMetaData, setFetchingCardMetaData] = useState(false);

  const [cardImageURL, setCardImageURL] = useState("/cardupdating.png");
  const [cardToBoost, setCardToBoost] = useState("");

  let formattedCardID = padLeadingZeros(cardID, 4);
  function padLeadingZeros(num, size) {
    var s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
  }

  async function fetchCardData() {

    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let cardData_result = [];

    try {

      let fetchedCardBalance = await contract.balanceOf(signedInAddress, cardID);
      cardData_result[9] = fetchedCardBalance.toNumber();

      let fetchedCardData = await contract.cards(cardID);
      cardData_result[0] = fetchedCardData[0];
      cardData_result[1] = fetchedCardData[1].toNumber();
      cardData_result[2] = fetchedCardData[2].toNumber();
      cardData_result[3] = fetchedCardData[3].toNumber();
      cardData_result[4] = fetchedCardData[4].toNumber();
      cardData_result[5] = fetchedCardData[5].toNumber();
      cardData_result[6] = fetchedCardData[6].toNumber();
      cardData_result[7] = fetchedCardData[7].toNumber();
      cardData_result[8] = fetchedCardData[8].toNumber();

      let block = provider.getBlockNumber();
      let blockDetails = await provider.getBlock(block);
      let currentBlockTimeStamp = blockDetails.timestamp;

      let oneDay = 86400;
      let oneHour = 3600;
      let oneMinute = 60;
      let cardRedeemInSeconds = cardData_result[5] * oneDay;
      let daysLeft = Math.ceil(((cardData_result[6] + cardRedeemInSeconds) - currentBlockTimeStamp) / oneDay);
      let hoursLeft = Math.ceil(((cardData_result[6] + cardRedeemInSeconds) - currentBlockTimeStamp) / oneHour);
      let minutesLeft = (((cardData_result[6] + cardRedeemInSeconds) - currentBlockTimeStamp) / oneMinute).toFixed(0);

      if((cardData_result[6] + cardRedeemInSeconds) < currentBlockTimeStamp) {
        cardData_result[6] = "redeemTimerAvailable";
      } else {
        if(hoursLeft <= 1) {
          if (minutesLeft <= 60 && minutesLeft > 1) {
              cardData_result[6] = + minutesLeft + " minutes left";
          } else {
            cardData_result[6] = " < minute";
          }
        } else if(daysLeft <= 1) {
            cardData_result[6] = hoursLeft + " hours left";
        } else {
          cardData_result[6] = + daysLeft + " days left";
        }
      }
    } catch (error) {
      cardData_result = error;
    }
    setCardData(cardData_result);
  };

  async function fetchMetaData() {

    const metaDataURL = 'https://api.lync.network/api/token/';
    const cardMetaDataURL = metaDataURL + cardID;
    const openseaURL = 'https://opensea.io/assets/' + addresses.lyncCrafter + '/' + cardID;
    const etherscanURL = 'https://etherscan.io/token/' + addresses.lyncCrafter + '?a=' + cardID + '#inventory';

    let cardMetaDataArray = [];

    try {
      let response = await fetch(cardMetaDataURL);
      let cardMetaData = await response.json();

      cardMetaDataArray[0] = cardMetaData.image;
      cardMetaDataArray[1] = cardMetaData.name;
      cardMetaDataArray[2] = cardMetaData.description;
      cardMetaDataArray[3] = openseaURL;
      cardMetaDataArray[4] = etherscanURL;

    } catch (error) {
      cardMetaDataArray = error;
    }
    setCardMetaData(cardMetaDataArray);
    setCardImageURL(cardMetaDataArray[0]);
  };

  useEffect(() => {
    if (provider && currentNetwork === correctNetwork) {
      if (!fetchingCardData && !fetchingCardMetaData) {
        fetchCardData();
        setFetchingCardData(true);
        fetchMetaData();
        setFetchingCardMetaData(true);
      }
    }
  }, [provider, currentNetwork, correctNetwork, fetchCardData, fetchingCardData, fetchMetaData, fetchingCardMetaData]);

  return (
    <div>
      <Card className="nft-single">
        <Card.Header>CARD #{formattedCardID}</Card.Header>
        <Card.Body>
          <div className="nft-image">
          {(cardData[1] !== 0) ?
            <>
            {(cardData[9] > 1) &&
              <div className="nft-balance"><span>x{cardData[9]}</span></div>
            }
            {(cardMetaData[0] === undefined) ?
              <img src="./metaerror.png" alt="NFT Artwork" />
            :
            <img src={cardImageURL} alt="NFT Artwork" />
            }
          </> : <img src="./nocard.png" alt="NFT Artwork" />
          }
        </div>

          {(cardMetaData[0] !== "0") ?
            <>
              {(cardData[1] === 0) ?
                <Card.Title>NO CARD EXISTS</Card.Title>
              : <>
                {(cardMetaData[1]) ?
                  <Card.Title>{cardMetaData[1]}</Card.Title>
                : <Card.Title>Missing Metadata</Card.Title>
                }
              </>
              }
            </> :
            <Card.Title><Spinner animation="grow" variant="secondary" /></Card.Title>
          }

          {(cardData !== "0") ?
            <>
              {(cardData[0] !== "") ?
                <Card.Subtitle className="mb-2 text-muted">Part of the <b>{cardData[0]}</b> collection</Card.Subtitle>
              : <></>}

              {(cardData[1] === 3) ?
                <Card.Subtitle className="mb-2 text-muted">Booster Card</Card.Subtitle>
              : <></>}

              {(cardData[1] === 1) ?
                <Card.Subtitle className="mb-2 text-muted">Destructable Card</Card.Subtitle>
              : <></>}
            </> :
            <Card.Subtitle className="mb-2 text-muted">fetching chaindata...</Card.Subtitle>
          }

          {(cardMetaData !== "0") ?
            <>
              {(cardData[1] === 0) ?
                <Card.Text className="nft-description">No card has been crafted on this ID at present.</Card.Text>
              : <>
                {(cardMetaData[2]) ?
                  <Card.Text className="nft-description">{cardMetaData[2]}</Card.Text>
                : <Card.Text className="nft-description">This card exists but either the metadata is missing or there was an issue retrieving it.</Card.Text>
                }
                </>
              }
            </> : <Card.Text className="nft-description"></Card.Text>
          }

          <hr className="nft-break-line"></hr>
          <div>

          {(cardData !== "0") ?
            <>
              {(cardData[1] === 1 || cardData[1] === 3) ?
                <Card.Subtitle className="mb-2 text-muted">These cards <b>DESTRUCT</b> when redeemed!</Card.Subtitle> :
                <>
                {(cardData[1] === 2) ?
                  <>
                    {(cardData[4] !== 0) ?
                      <Card.Subtitle className="mb-2 text-muted">This card can be boosted!</Card.Subtitle>
                    : <></>
                    }
                 </>
                : <></>
                }
            </>
          }

                <div className="nft-data-background">
                  <Table borderless variant="dark">
                    <tbody>
                      {(cardData[1] === 3) &&
                        <tr className="nft-table-row">
                          <td>Boosts</td>
                          <td>{cardData[2]}</td>
                        </tr>
                      }

                      {(cardData[1] === 2) &&
                        <>
                        {(cardData[3] !== 0) ?
                          <>
                            <tr className="nft-table-row">
                              <td>Redeems</td>
                              {(cardData[4] !== 0) ?
                                <>
                                  {(cardData[4] > cardData[3]) ?
                                    <td className="nft-boost">{cardData[4]} remaining</td> :
                                    <td>{cardData[4]} remaining</td>
                                  }
                                </> :
                                <td >Boost to Reactivate</td>
                              }
                            </tr>
                            <tr className="nft-table-row">
                              <td>Redeem Interval</td>
                              <td>Every {cardData[5]} days</td>
                            </tr>

                            {(cardData[6] !== "redeemTimerAvailable") &&
                              <tr className="nft-table-row">
                                <td>Redeem Timer</td>
                                <td className="nft-redeem-red">{cardData[6]}</td>
                              </tr>
                            }
                          </> : <></>
                        }
                      </>
                    }

                      {(cardData[7] !== 0) &&
                        <tr className="nft-table-row">
                          <td>Token Reward</td>
                          <td>{cardData[7]} LYNC Tokens</td>
                        </tr>
                      }

                      {(cardData[8] !== 0) &&
                        <tr className="nft-table-row">
                          <td>Percentage Reward</td>
                          <td>{cardData[8]}% on LYNC Staked</td>
                        </tr>
                      }
                    </tbody>
                  </Table>
                  {(cardData[1] === 3) &&
                    <Form className="nft-boost-form">
                      <Form.Group controlId="card-boost">
                        <Form.Row>
                          <Form.Label column="sm" lg={6}>Card to Boost</Form.Label>
                          <Col>
                            <Form.Control size="sm" type="text" placeholder="cardID" onChange={e => setCardToBoost(e.target.value)} />
                          </Col>
                        </Form.Row>
                      </Form.Group>
                    </Form>
                  }
                </div>
              </> :
              <Card.Subtitle className="mb-2 text-muted"><Spinner animation="grow" variant="secondary" /></Card.Subtitle>
            }
          </div>

        </Card.Body>
        <Card.Footer>

        {(cardData[1] !== 0) &&
        <div className="single-nft-links">
          <a href ={cardMetaData[3]} target="_blank" rel="noopener noreferrer"><img src="/opensealogo.svg" alt="View on OpenSea.io"/></a>
        </div>}

        <div className="nft-redeem-button">
        {(cardData !== "0") &&
          <RedeemButton provider={provider} cardID={cardID} cardToBoost={cardToBoost} cardData={cardData} />
        }
        </div>
        </Card.Footer>
      </Card>
    </div>
  )
}
