import lyncTokenAbi from "./abis/LYNCToken.json";
import lyncStakingAbi from "./abis/LYNCStaking.json";
import lyncCrafterAbi from "./abis/LYNCCrafter.json";
import lyncRewardsAbi from "./abis/LYNCRewards.json";

const abis = {
  lyncToken: lyncTokenAbi,
  lyncStaking: lyncStakingAbi,
  lyncCrafter: lyncCrafterAbi,
  lyncRewards: lyncRewardsAbi,
};

export default abis;
