import React, { useState, useEffect } from "react";

import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

import { NavLink, Route, Switch } from 'react-router-dom';
import useWeb3Modal from "./hooks/useWeb3Modal";
import Nav from 'react-bootstrap/Nav';
import Error404 from './components/error404';
import PageHeader from './components/pageheader';
import Staking from './components/staking';
import CardChecker from './components/cardchecker';
import CardCrafters from './components/cardcrafters';
import CardWallet from './components/cardwallet';
import HomePage from './components/homepage';
import Tracking from './components/tracking';
import UserGuides from './components/userguides';

function App() {

  const CORRECT_NETWORK = "1";

  const [provider, loadWeb3Modal, logoutOfWeb3Modal, signedInAddress, chainID] = useWeb3Modal();
  const [crafterOwnerAddress, setCrafterOwnerAddress] = useState();
  const [fetchingOwnerAddress, setFetchingOwnerAddress] = useState(false);
  const [lyncPriceUSD, setLyncPriceUSD] = useState("0.00");
  const [fetchinglyncPriceUSD, setFetchingLyncPriceUSD] = useState(false);

  async function fetchCrafterOwner() {
    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let crafterOwner_result;
    try {
      let fetchedAddress = await contract.owner();
      crafterOwner_result = fetchedAddress.toLowerCase();
    } catch (error) {
      crafterOwner_result = error;
    }
    setCrafterOwnerAddress(crafterOwner_result);
  }

  async function fetchLyncPrice() {

    const coinGeckoAPI = 'https://api.coingecko.com/api/v3/simple/price?ids=lync-network&vs_currencies=usd';
    let lyncPriceUSD;

    try {
      let response = await fetch(coinGeckoAPI);
      let coinGeckoData = await response.json();
      let lyncData = coinGeckoData.["lync-network"];
      lyncPriceUSD = lyncData.usd;

    } catch (error) {
      lyncPriceUSD = error;
    }
    setLyncPriceUSD(lyncPriceUSD);
  }

  useEffect(() => {
    if(!fetchinglyncPriceUSD) {
      fetchLyncPrice();
      setFetchingLyncPriceUSD(true);
    }
    if (provider) {
      if (!fetchingOwnerAddress) {
        fetchCrafterOwner();
        setFetchingOwnerAddress(true);
      }
    }
  }, [fetchinglyncPriceUSD, provider, fetchCrafterOwner, fetchingOwnerAddress]);

  return (
    <div className="lync-network">
      <PageHeader
        provider={provider}
        currentNetwork={chainID}
        correctNetwork={CORRECT_NETWORK}
        loadWeb3Modal={loadWeb3Modal}
        logoutOfWeb3Modal={logoutOfWeb3Modal}
        signedInAddress={signedInAddress}
        lyncPriceUSD={lyncPriceUSD}
      />

      <main>
        <Nav variant="pills">
          <Nav.Item>
            <Nav.Link as={NavLink} to="/" eventKey="/" exact>Home</Nav.Link>
          </Nav.Item>

          {(signedInAddress && chainID === CORRECT_NETWORK) ?
            <>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/staking" eventKey="/staking">Staking</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/card-wallet" eventKey="/card-wallet">NFT Card Wallet</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link as={NavLink} to="/card-checker" eventKey="/card-checker">Card Checker</Nav.Link>
              </Nav.Item>
            </> :
            <>
              <Nav.Item>
                <Nav.Link disabled>Staking</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled>NFT Card Wallet</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link disabled>Card Checker</Nav.Link>
              </Nav.Item>
            </>
          }

          <Nav.Item>
            <Nav.Link as={NavLink} to="/user-guides" eventKey="/user-guides">User Guides</Nav.Link>
          </Nav.Item>

          {(signedInAddress === crafterOwnerAddress && chainID === CORRECT_NETWORK) &&
            <Nav.Item className="card-crafters">
              <Nav.Link as={NavLink} to="/card-crafters" eventKey="/card-crafters">Card Crafters</Nav.Link>
            </Nav.Item>
          }
        </Nav>

        <Switch>
          <Route path="/" component={HomePage} exact />
          <Route path="/tracking" component={Tracking} exact />
          <Route path="/staking" render={(props) => <Staking provider={provider} currentNetwork={chainID} correctNetwork={CORRECT_NETWORK} signedInAddress={signedInAddress} lyncPriceUSD={lyncPriceUSD} {...props} /> } exact />
          <Route path="/card-wallet" render={(props) => <CardWallet provider={provider} currentNetwork={chainID} correctNetwork={CORRECT_NETWORK} signedInAddress={signedInAddress} {...props} /> } exact />
          <Route path="/card-checker" render={(props) => <CardChecker provider={provider} currentNetwork={chainID} correctNetwork={CORRECT_NETWORK} {...props} /> } exact />
          <Route path="/card-crafters" render={(props) => <CardCrafters provider={provider} currentNetwork={chainID} correctNetwork={CORRECT_NETWORK} {...props} /> } exact />
          <Route path="/user-guides" component={UserGuides} />
          <Route component={Error404} />
        </Switch>
      </main>

    </div>
  );
}

export default App;
