import React from "react";
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';

export default function UserGuides() {

  return (
    <div className="user-guides">
      <Accordion>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="0">
            <h5>STAKING</h5>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body>
              <h6>OVERVIEW</h6>
              <p>Staking LYNC tokens will allow the staker to recieve a percentage of the Reward Pool every 24 hours. The 24 hour timer resets once a claim has been made. The Reward Pool is funded by every token transfer and the 5% un-stake fee.</p>
              <h6>APY</h6>
              <p>One question that is constantly asked is "what is the staking APY?" Let's clear that up first! We cannot answer this as the Reward Pool is funded by the 1% transaction fee on all transfers and the end-of stake fee so if more tokens are transfered, the higher the APY, it can fluctuate greatly.</p>
              <h6>COMPOUNDING</h6>
              <p>There is <span className="user-guides-red">NO</span> compounding of rewards, to compound you will need to claim your rewards and re-stake the tokens. If compounding was enabled, there would be no claimed tokens, meaning that there would be no transaction fees and no additional tokens to the Reward Pool.</p>
              <h6>REWARD POOL</h6>
              <p>The Reward Pool balance is based on LYNC tokens generated from the 1% transaction fee <span className="user-guides-blue">SINCE YOUR LAST CLAIM</span> and will reset to zero when you claim any rewards.</p>
              <h6>APPROVE / REVOKE</h6>
              <p>To stake tokens you will need to <span className="user-guides-green">APPROVE</span> the staking contract to spend your LYNC tokens, simply click the <span className="user-guides-green">APPROVE</span> button. The "Stake Tokens" option will NOT be visible until the contract has been approved. If you have <span className="user-guides-green">APPROVED</span> the staking contract, the button will change so you can <span className="user-guides-red">REVOKE</span> access if you no longer want the staking contract to use your LYNC tokens.</p>
              <h6>STAKE TOKENS</h6>
              <p>Simply enter the amount of tokens you wish to stake and click <span className="user-guides-green">STAKE TOKENS</span> If there is no option to stake tokens then you need to click <span className="user-guides-green">APPROVE</span> first. There is no longer a minimum requirement for token quantity (previously 100 tokens)</p>
              <h6>CLAIM REWARDS</h6>
              <p>This option will only be visible if you have staked tokens. Rewards are estimated and can be claimed once every 24 hours. You will be presented with a countdown to your next claim if you have recently started staking or have claimed within 24 hours. Rewards are <span className="user-guides-blue">ESTIMATED</span> and not exact and will fluctuate depending tokens staked/unstaked.</p>
              <h6>UNSTAKE TOKENS</h6>
              <p>This option will only be visible if you have staked tokens. When unstaking tokens you MUST claim any rewards that are pending or <span className="user-guides-red">YOU WILL LOSE THEM!</span> There is a 5% FEE (4% end-of-stake fee and 1% transaction fee) which goes directly to the Reward Pool.</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="1">
            <h5>NFT CARD WALLET</h5>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="1">
            <Card.Body>
              <h6>OVERVIEW</h6>
              <p>The NFT Card Wallet is specifically designed for the <span className="user-guides-blue">LYNC Network NFT Crafter</span> contract and will display your LYNC NFT Cards that you own along with the quantity of each card, it will not display NFTs you own from other contracts. All cards can be viewed on any NFT platform such as OpenSea but the reason we created our own wallet is that each NFT Card we mint can hold special data. Outlined below is an overview of the various card types and features of specific cards.</p>
              <h6>CRAFTING CARDS</h6>
              <p>There will be certain cards that allow the owner to craft unique <span className="user-guides-purple">Reward Cards</span> which will have special rewards attached to them. These cards will need a particualr quantity before the <span className="user-guides-purple">Reward Card</span> can be crafted, the quantity needed will be shown below the card such as "3 of 10" if you own 3 of the required 10 cards needed to craft. Once you have the correct quantity this will be replaced with <span className="user-guides-green">CRAFT CARD</span></p>
              <h6>DESTRUCTABLE CARDS</h6>
              <p>Destructables can be used to craft other cards such as <span className="user-guides-purple">Reward Cards</span> as mentioned above along with the ability to redeem token and/or staking rewards associated with the specific card one a one time basis. Once redeemed the card will burn. These cards <span className="user-guides-red">CANNOT</span> be boosted (see Booster Cards below)</p>
              <h6>COLLECTION CARDS</h6>
              <p><span className="user-guides-blue">Collection Cards</span> are the most varied cards that will be avaiable. They have the ability to simply be a Unique NFT with no rewards, part of a collection of cards in a set and also as a way to offer redeemable rewards that can be used over and over again with any given interval. These cards also have the ability to be Boosted (see Booster Cards below) which means the rewards attached to them continue to be used even once the intital redeem count has expired. <span className="user-guides-blue">Collection Cards</span> also <span className="user-guides-red">NEVER</span> burn so can be kept indefinitely.</p>
              <h6>BOOSTER CARDS</h6>
              <p><span className="user-guides-blue">Booster Cards</span> allow the owner to increase the available redeems on a <span className="user-guides-blue">Collection Card</span> that has rewards attached to it by a given amount. For example a <span className="user-guides-blue">Collection Card</span> which had 3 redeems, each allowing for 500 tokens has been redeemed 3 times and this card can no longer be used. Boosting this card with 5 additional redeems will reactivate the card and it can now be used 5 more times. Owners of <span className="user-guides-blue">Booster Cards</span> can boost any <span className="user-guides-blue">Collection Card</span> they wish, they do not need to own the card they are boosting. A <span className="user-guides-blue">Collection Card</span> can be boosted at anytime not only when it has been depleted, if the card being boosted exceeds it's original redeem count, this will be indicated with a <span className="user-guides-boost"></span> after the number of remaining redeems. <span className="user-guides-blue">Booster Cards</span> <span className="user-guides-red">DESTRUCT</span> once used.</p>
              <h6>REDEEMS</h6>
              <p>Redeems indicate how many times a <span className="user-guides-blue">Collection Card</span> can be used. Each time the card is redeemed this will decrease until it can no longer be used. Booster Cards can be used to increase the number of redeems at anytime.</p>
              <h6>REDEEM INTERVAL</h6>
              <p>The redeem interval is how often the card can be used. Once redeemed, a countdown will begin until the card can be redeemed again.</p>
              <h6>TOKEN AND PERCENTAGE REWARD</h6>
              <p>Both <span className="user-guides-blue">Destructable Cards</span> and <span className="user-guides-blue">Collection Cards</span> can have rewards attached to them. This is how many tokens the owner will recieve when redeeming the card. Token Reward is how many tokens will be rewarded per redeem. Percentage Reward is a percentage against the tokens staked by the owner per redeem.</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="2">
            <h5>LOTTO CARD REDEEMING</h5>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="2">
            <Card.Body>
              <h6>ETH / LYNC LOTTO COMMON</h6>
              <p>Players that participated in the ETH and LYNC Lotto (which is now end-of-life) will receive a single <span className="user-guides-green">Common Card</span> associated with the lotto they entered. On the LYNC NFT Card Wallet, you will require 10 <span className="user-guides-green">Common Cards</span> to craft the corresponding <span className="user-guides-blue">Classic Reward Card</span> that wil allow for 400 Tokens to be instantly claimed, 4% of LYNC staked and can be used once every 28 days up to a total of 6 times (this can be boosted also) The NFT Card Wallet will show how many <span className="user-guides-green">Common Cards</span> you have and how many are required, once 10 cards are acquired the <span className="user-guides-red">CRAFT CARD</span> option will become available, simply click this to mint your <span className="user-guides-blue">Classic Reward Card</span>.</p>
              <h6>ETH / LYNC LOTTO RARE</h6>
              <p>Players that won a round of the ETH and LYNC Lotto (which is now end-of-life) will receive a single <span className="user-guides-purple">Rare Card</span> associated with the lotto they entered. On the LYNC NFT Card Wallet, you will only require 1 <span className="user-guides-purple">Rare Card</span> to craft the corresponding <span className="user-guides-blue">Classic Reward Card</span> that wil allow for 400 Tokens to be instantly claimed, 4% of LYNC staked and can be used once every 28 days up to a total of 6 times (this can be boosted also) The NFT Card Wallet will show how many <span className="user-guides-purple">Rare Cards</span> you have, simply click the <span className="user-guides-red">CRAFT CARD</span> to mint your <span className="user-guides-blue">Classic Reward Card</span>.</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
        <Card>
          <Accordion.Toggle as={Card.Header} eventKey="3">
            <h5>CARD CHECKER</h5>
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="3">
            <Card.Body>
              <h6>OVERVIEW</h6>
              <p>The Card Checker is a quick and easy way to check any of the LYNC NFT Cards that have been crafted. This is especially useful if you are looking to purchase a <span className="user-guides-purple">Reward Card</span> that is listed for sale and you want to check any rewards associated with the card, redeems that are available and also any countdown timers that may still be outstanding on that specific card.</p>
              <h6>CHECK CARD</h6>
              <p>To check a card, you will need the <span className="user-guides-blue">Token ID</span> that can be located on OpenSea under the <span className="user-guides-blue">Chain Info</span> dropdown. To check the card simply enter the <span className="user-guides-blue">Token ID</span> and click <span className="user-guides-green">CHECK CARD</span> and the information relating to that card will be displayed.</p>
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
    </div>
  )
}
