import React, { useEffect, useState } from "react";
import CardCollection from './crafters/collection';
import CardBulk from './crafters/bulk';
import CardBooster from './crafters/booster';
import CardIncreaser from './crafters/increaser';
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function CardCrafters({ provider, signedInAddress, currentNetwork, correctNetwork}) {

  const [nextCardID, setNextCardID] = useState("0");
  const [fetchingCardID, setFetchingCardID] = useState(false);

  async function getNextCardID(provider) {

    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let nextCard_result;
    try {
      let fetchedNextCard = await contract.cardID();
      nextCard_result = fetchedNextCard.toNumber();
    } catch (error) {
      nextCard_result = error.message;
    }
    return nextCard_result;
  }

  async function fetchCardID() {
    let nextCard = await getNextCardID(provider);
    setNextCardID(nextCard);
  };

  useEffect(() => {
    if (provider && currentNetwork === correctNetwork) {
      if (!fetchingCardID) {
        fetchCardID();
        setFetchingCardID(true);
      }
    }
  }, [provider, currentNetwork, correctNetwork, fetchCardID, fetchingCardID]);

  return (
    <>
      {(currentNetwork !== correctNetwork) ?
        <div className="crafters-subheader">
        Connect Wallet and Check Network
        </div> :
        <>
          <div className="crafters-subheader">
          Next card ID will be {nextCardID}
          </div>
          <CardCollection provider={provider} signedInAddress={signedInAddress} />
          <CardBulk provider={provider} signedInAddress={signedInAddress} />
          <CardBooster provider={provider} signedInAddress={signedInAddress} />
          <CardIncreaser provider={provider} signedInAddress={signedInAddress} />
        </>
      }
    </>
  )
}
