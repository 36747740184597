import React from "react";

export default function Tracking() {

  return (
    <div className="home">
    <h6>LYNC Network Contracts and Tracking</h6>
    <p>(update in progress...)</p>
    </div>
  )
}
