import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function StakingUnstake({ provider, signedInAddress }) {

  const SUCCESS_MSG = "Success! Transaction has been submitted to the network. Please wait for confirmation on the blockchain.";

  async function unstake() {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncStaking, abis.lyncStaking, provider);
    let signed = await contract.connect(signer);
    let unstakeTokens_result;

    try {
      await signed.unstakeTokens();
      unstakeTokens_result = SUCCESS_MSG;
    } catch (error) {
      unstakeTokens_result = error;
    }
    alert(unstakeTokens_result);
    return unstakeTokens_result;
  }

  return (
    <div>
      <Card className="staking-single">
        <Card.Body>
          <Card.Title>Unstake Tokens</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">5% end-of-stake fee is applied</Card.Subtitle>
          <Card.Text>Claim your rewards before unstaking or<br /> YOU WILL LOSE THEM!</Card.Text>

          <div className = "centered-submit-buttons">
            <Button variant="success" onClick={unstake}>UNSTAKE TOKENS</Button>
          </div>
        </Card.Body>
      </Card>
    </div>
  )
}
