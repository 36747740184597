import React, { useState, useEffect } from "react";
import { useHistory } from 'react-router-dom';

import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { addresses, abis } from "@project/contracts";

import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

function WalletButton({ provider, loadWeb3Modal, logoutOfWeb3Modal }) {

  const history = useHistory();

  return (
    <Button className="address-button"
      variant="primary"
      onClick={() => {
        if (!provider) {
          loadWeb3Modal();
        } else {
          logoutOfWeb3Modal();
          history.push('/');
        }
      }}
    >
      {!provider ? "Connect Wallet" : "Disconnect"}
    </Button>
  );
}

export default function PageHeader({ provider, currentNetwork, correctNetwork, loadWeb3Modal, logoutOfWeb3Modal, signedInAddress, lyncPriceUSD }) {

  function truncateAddress(addr) {
    if(addr !== undefined) {
      let prefix = addr.substring(0,6);
      let suffix = addr.substring(addr.length - 4);
      return prefix + "..." + suffix;
    } else {
      return "No Address Detected";
    }
  }

  const [lyncBalance, setLyncBalance] = useState("0");
  const [fetchingLyncBalance, setFetchingLyncBalance] = useState(false);

  async function fetchLyncBalance() {

    let contract = new Contract(addresses.lyncToken, abis.lyncToken, provider);
    let userBalance_result;
    try {
      let fetchedBalance = await contract.balanceOf(signedInAddress);
      let convertBalance = ethers.utils.formatEther(fetchedBalance);
      let commifyBalance = parseFloat(convertBalance).toFixed();
      userBalance_result = ethers.utils.commify(commifyBalance);

    } catch (error) {
      userBalance_result = error;
    }
    setLyncBalance(userBalance_result);
  }

  useEffect(() => {
    if (provider) {
      if (signedInAddress) {
        if (lyncBalance === "0" && !fetchingLyncBalance) {
          fetchLyncBalance();
          setFetchingLyncBalance(true);
        }
      }
    }
  }, [signedInAddress, fetchingLyncBalance, fetchLyncBalance, lyncBalance, provider]);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="lg">
        <Navbar.Brand><img className="logo-header" src="/lynclogoblue.png" width="30px" height="30px" alt="LYNC Network Logo"></img>LYNC NETWORK</Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse >
          <Nav className="mr-auto">
            Token Staking and NFT Reward Cards
          </Nav>
          <Nav>
            <Nav.Item style={{padding: ".5rem .2rem"}}>
              <span className="lync-price">1 LYNC : ${lyncPriceUSD}</span>
            </Nav.Item>
            {(signedInAddress !== "") &&
              <>
                {(currentNetwork === correctNetwork) ?
                  <>
                    <Nav.Item style={{padding: ".5rem .2rem"}}>
                      <span className="lync-balance">{lyncBalance} LYNC</span>
                    </Nav.Item>
                    <Nav.Item style={{padding: ".5rem .5rem"}}>
                      <span className="connected-address">{truncateAddress(signedInAddress)}</span>
                    </Nav.Item>
                  </> :
                  <>
                    <Nav.Item style={{padding: ".5rem .2rem"}}>
                      <span className="wrong-network">WRONG NETWORK</span>
                    </Nav.Item>
                    <Nav.Item style={{padding: ".5rem .2rem"}}>
                      <span className="connected-address">{truncateAddress(signedInAddress)}</span>
                    </Nav.Item>
                  </>
                }
              </>
            }
            <WalletButton provider={provider} loadWeb3Modal={loadWeb3Modal} logoutOfWeb3Modal={logoutOfWeb3Modal} />
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  )
}
