import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function CardCollection({ provider, signedInAddress }) {

  const SUCCESS_MSG = "Success! Collection Cards been crafted and submitted to the network. Please wait for confirmation on the blockchain.";

  const [toAddress, setToAddress] = useState("0x0");
  const [amount, setAmount] = useState("0");
  const [collectionName, setCollectionName] = useState("0");
  const [redeemInitial, setRedeemInitial] = useState("0");
  const [redeemInterval, setRedeemInterval] = useState("0");
  const [tokenReward, setTokenReward] = useState("0");
  const [percentageReward, setPercentageReward] = useState("0");


  async function craftCollectionCard(w3provider, toAddress, collectionName, amount, redeemInitial, redeemInterval, tokenReward, percentageReward) {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let signed = await contract.connect(signer);
    let collectionCraft_result;
    try {
      await signed.craftCollectionCard(toAddress, collectionName, amount, redeemInitial, redeemInterval, tokenReward, percentageReward);
      collectionCraft_result = SUCCESS_MSG;
    } catch (error) {
      collectionCraft_result = error;
    }
    alert(collectionCraft_result);
    return collectionCraft_result;
  }

  function craftCards() {
    if(amount === "0") {
      alert("You cannot craft zero cards!")
    } else {
      craftCollectionCard(provider, toAddress, collectionName, amount, redeemInitial, redeemInterval, tokenReward, percentageReward)
    }
  }

  return (
    <div className="crafter-single">
      <Card>
        <Card.Body>
          <Card.Title>Collection / Unique Crafter</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">CREATE COLLECTION CARDS</Card.Subtitle>
          <Card.Text className="card-crafter-description">Creates cards that can be multi-use and also part of collections. Each card has a unique cardID which allows the owner to keep the card once it has been used (if enabled). These cards can also be boosted.</Card.Text>

          <Form>
            <Form.Group>
              <Form.Row>
                <Form.Label column="sm" lg={4}>Owner Address:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="0x0" onChange={e => setToAddress(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Collection Name:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 'Summer Reward Pack'" onChange={e => setCollectionName(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Amount to Craft:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="Total cards to craft" onChange={e => setAmount(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Initial Redeems:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 5 (redeem 5 times)" onChange={e => setRedeemInitial(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Redeem Interval:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 7 (once every 7 days)" onChange={e => setRedeemInterval(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Token Reward:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 2500 (2500 tokens)" onChange={e => setTokenReward(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Percent Reward:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 10 (10% on stake)" onChange={e => setPercentageReward(e.target.value)} />
                </Col>
              </Form.Row>
            </Form.Group>
            <div className = "centered-submit-buttons">
              <Button variant="success" onClick={craftCards}>CRAFT COLLECTION CARDS</Button>
            </div>
          </Form>

        </Card.Body>
      </Card>
    </div>
  )
}
