import React, { useEffect, useState } from "react";
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { addresses, abis } from "@project/contracts";

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Approve from './staking/approve';
import Stake from './staking/stake';
import Unstake from './staking/unstake';
import Claim from './staking/claim';

export default function Staking({ provider, signedInAddress, currentNetwork, correctNetwork, lyncPriceUSD }) {

  const [fetchingData, setFetchingData] = useState(false);

  const [stakerInformation, setStakerInformation] = useState("0");
  const [stakerApproved, setStakerApproved] = useState("0");
  const [stakerShare, setStakerShare] = useState("0");
  const [poolSinceClaim, setPoolSinceClaim] = useState("0");
  const [stakerReward, setStakerReward] = useState("0");

  const [lyncRewardValue, setLyncRewardValue] = useState("0.00");

  const [displayTotalStaked, setDisplayTotalStaked] = useState("0");
  const [displayStakerStaked, setDisplayStakerStaked] = useState("0");

  async function getStakingData() {

    let contract = new Contract(addresses.lyncStaking, abis.lyncStaking, provider);
    let stakingData_result = [];

    try {
      let totalStaked = await contract.totalStakedV1();
      let convertStaked = ethers.utils.formatEther(totalStaked);
      stakingData_result[0] = parseFloat(convertStaked).toFixed();

      let rewardPool = await contract.rewardPoolBalance();
      let convertRewardPool = ethers.utils.formatEther(rewardPool);
      stakingData_result[1] = parseFloat(convertRewardPool).toFixed();

      let totalRewards = await contract.totalRewards();
      let convertTotalRewards = ethers.utils.formatEther(totalRewards);
      stakingData_result[2] = parseFloat(convertTotalRewards).toFixed();

    } catch (error) {
      stakingData_result = error;
    }
    return stakingData_result;
  }

  async function getApprovedTokens() {

    let contract = new Contract(addresses.lyncToken, abis.lyncToken, provider);
    let approvedTokens_result;

    try {
      let fetchApprovedTokens = await contract.allowance(signedInAddress, addresses.lyncStaking);
      let approved = ethers.utils.formatEther(fetchApprovedTokens);
      approvedTokens_result = parseFloat(approved).toFixed();

    } catch (error) {
      approvedTokens_result = error;
    }
    return approvedTokens_result;
  }

  async function getStakerInformation() {

    let contract = new Contract(addresses.lyncStaking, abis.lyncStaking, provider);
    let stakerInformation_result = [];

    try {
      let fetchStakerInformation = await contract.stakerInformation(signedInAddress);

      let staked = ethers.utils.formatEther(fetchStakerInformation[0]);
      stakerInformation_result[0] = parseFloat(staked).toFixed()
      let poolLastClaim = ethers.utils.formatEther(fetchStakerInformation[1]);
      stakerInformation_result[1] = parseFloat(poolLastClaim).toFixed();
      stakerInformation_result[2] = fetchStakerInformation[2].toNumber();

      let block = provider.getBlockNumber();
      let blockDetails = await provider.getBlock(block);
      let currentBlockTimeStamp = blockDetails.timestamp;

      let oneDay = 86400;
      let oneHour = 3600;
      let oneMinute = 60;
      let hoursLeft = Math.ceil(((stakerInformation_result[2] + oneDay) - currentBlockTimeStamp)  / oneHour);
      let minutesLeft = (((stakerInformation_result[2] + oneDay) - currentBlockTimeStamp) / oneMinute).toFixed(0);

      if((stakerInformation_result[2] + oneDay) < currentBlockTimeStamp) {
        stakerInformation_result[3] = "claimAvailable";
      } else {
        if(hoursLeft <= 1) {
          if (minutesLeft <= 60 && minutesLeft > 1) {
              stakerInformation_result[3] = + minutesLeft + " minutes left";
          } else {
            stakerInformation_result[3] = " < minute";
          }
        } else {
          stakerInformation_result[3] = hoursLeft + " hours left";
        }
      }
    } catch (error) {
      stakerInformation_result = error;
    }
    return stakerInformation_result;
  }

  async function fetchData() {
    let data = await getStakingData();
    let commifyTotalStaked = ethers.utils.commify(data[0]);
    setDisplayTotalStaked(commifyTotalStaked);
    let staker = await getStakerInformation();
    setStakerInformation(staker);
    let commifyStakerStaked = ethers.utils.commify(staker[0]);
    setDisplayStakerStaked(commifyStakerStaked);
    let approved = await getApprovedTokens();
    setStakerApproved(approved);

    let share = ((staker[0] / data[0]) * 100).toFixed(1);
    let pool = (data[2] - staker[1]).toFixed();
    let reward = ((pool * share) / 100).toFixed();
    setStakerShare(share);
    if (staker[0] > "0") {
      let commifyPool = ethers.utils.commify(pool);
      setPoolSinceClaim(commifyPool);
    } else {
      setPoolSinceClaim("0");
    }
    let commifyReward = ethers.utils.commify(reward);
    setStakerReward(commifyReward);
    let rewardValue = (reward * lyncPriceUSD).toFixed(2);
    setLyncRewardValue(rewardValue);
  };

  useEffect(() => {
    if (provider && currentNetwork === correctNetwork) {
      if (signedInAddress  && !fetchingData) {
        if (lyncPriceUSD !== "0.00") {
          fetchData();
          setFetchingData(true);
        }
      }
    }
  }, [provider, currentNetwork, correctNetwork, signedInAddress, lyncPriceUSD, fetchData, fetchingData]);

  return (
    <>
      {(currentNetwork !== correctNetwork) ?
        <div className="staking-subheader">
          Connect Wallet and Check Network
        </div> :
        <>
          <div className="staking-subheader">
            <Container>
              <Row>
                <Col>TOTAL STAKED:</Col>
                <Col>{displayTotalStaked} LYNC</Col>
              </Row>
              <Row>
                <Col>REWARD POOL: <span className="staking-reward-pool-notice">(since claim)</span></Col>
                <Col>{poolSinceClaim} LYNC</Col>
              </Row>
              <Row>
                <Col>TOKENS STAKED:</Col>
                <Col>{displayStakerStaked} LYNC ({stakerShare}%)</Col>
              </Row>
              <Row>
                <Col>ESTIMATED REWARD:</Col>
                <Col>{stakerReward} LYNC (${lyncRewardValue})</Col>
              </Row>
            </Container>
          </div>
          <Approve provider={provider} signedInAddress={signedInAddress} stakerApproved={stakerApproved} />
          {(stakerApproved > "100") &&
            <Stake provider={provider} signedInAddress={signedInAddress} />
          }
          {(stakerInformation[0] > "0") &&
            <>
              <Claim provider={provider} signedInAddress={signedInAddress} stakerInformation={stakerInformation} stakerReward={stakerReward} />
              <Unstake provider={provider} signedInAddress={signedInAddress} />
            </>
          }
        </>
      }
    </>
  )
}
