import React from "react";
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function StakingClaim({ provider, signedInAddress, stakerInformation, stakerReward }) {

  const SUCCESS_MSG = "Success! Transaction has been submitted to the network. Please wait for confirmation on the blockchain.";

  async function claim() {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncStaking, abis.lyncStaking, provider);
    let signed = await contract.connect(signer);
    let claimRewards_result;

    try {
      await signed.claimRewards();
      claimRewards_result = SUCCESS_MSG;
    } catch (error) {
      claimRewards_result = error;
    }
    alert(claimRewards_result);
    return claimRewards_result;
  }

  return (
    <div>
      <Card className="staking-single">
        <Card.Body>
          <Card.Title>Claim Rewards</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">1% fee applied to claim amount only</Card.Subtitle>
          <Card.Text>Available every 24 hours. Your next reward claim is estimated at {stakerReward} LYNC</Card.Text>
          {(stakerInformation[3] === "claimAvailable") ?
            <div className = "centered-submit-buttons">
              <Button variant="success" onClick={claim}>CLAIM REWARD</Button>
            </div> :
            <div className="staking-countdown">
             {stakerInformation[3]}
            </div>
          }
        </Card.Body>
      </Card>
    </div>
  )
}
