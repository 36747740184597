import React, { useState } from "react";
import CheckedCard from "./checkedcard";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

export default function CardChecker({ provider, currentNetwork, correctNetwork }) {

  const [cardToCheck, setCardToCheck] = useState("0");
  const [cardSubmitted, setCardSubmitted] = useState("0");

  function checkCard() {
    if(cardToCheck === "") {
      alert("Card to check cannot be blank");
    } else if(cardToCheck === "0") {
      alert("Card to check must be greater than 0");
    } else {
      setCardSubmitted(cardToCheck);
    }
  }

  return (
    <div>
      {(currentNetwork === correctNetwork) ?
        <>
          <div className="checker">
          <Row>
            <Col>
              <div className="checker-frame">
                <Button className="checker-button" variant="success" onClick={checkCard}>CHECK CARD</Button>
                <Form>
                  <Form.Row>
                    <Col className="checker-form">
                      <Form.Control size="sm" type="text" placeholder="Token ID" onChange={e => setCardToCheck(e.target.value)} />
                    </Col>
                  </Form.Row>
                </Form>
              </div>

            </Col>
          </Row>
          <Row>
            <Col>
              <CheckedCard provider={provider} cardToCheck={cardSubmitted} />
            </Col>
          </Row>
        </div>
        </> :
        <div className="nft-subheader">
            Connect Wallet and Check Network
        </div>
      }
    </div>
  )
}
