import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';

import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { addresses, abis } from "@project/contracts";

export default function StakingStake({ provider, signedInAddress }) {

  const SUCCESS_MSG = "Success! Transaction has been submitted to the network. Please wait for confirmation on the blockchain.";

  const [tokensToStake, setTokensToStake] = useState("0");

  async function stake() {
    if(tokensToStake === "") {
      alert("Enter a token amount");
    } else if(tokensToStake === "0") {
      alert("Cannot stake 0 tokens");
    } else {
      let signer = provider.getSigner();
      let contract = new Contract(addresses.lyncStaking, abis.lyncStaking, provider);
      let signed = await contract.connect(signer);
      const convertedAmount = ethers.utils.parseEther(tokensToStake);
      let stakeTokens_result;

      try {
        await signed.stakeTokens(convertedAmount);
        stakeTokens_result = SUCCESS_MSG;
      } catch (error) {
        stakeTokens_result = error;
      }
      alert(stakeTokens_result);
      return stakeTokens_result;
    }
  }

  return (
    <div>
      <Card className="staking-single">
        <Card.Body>
          <Card.Title>Stake Tokens</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">Amount of LYNC tokens to stake</Card.Subtitle>

          <Form>
            <Form.Group controlId="stake-tokens">
            <InputGroup>
              <InputGroup.Prepend>
                <InputGroup.Text>LYNC</InputGroup.Text>
              </InputGroup.Prepend>
                <Form.Control type="text" placeholder="0" onChange={e => setTokensToStake(e.target.value)} />
              </InputGroup>
            </Form.Group>

            <div className = "centered-submit-buttons">
              <Button variant="success" onClick={stake}>STAKE TOKENS</Button>
            </div>

          </Form>
        </Card.Body>
      </Card>
    </div>
  )
}
