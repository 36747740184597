import React from "react";

export default function error404() {

  return (
    <div className="error404">
    <h4>UH OH!</h4>
    <p>You have somehow managed to hit a dead link!</p>
    </div>
  )
}
