import React from "react";
import Button from 'react-bootstrap/Button';
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function SingleCard({ provider, cardID, cardToBoost, cardData }) {

  const SUCCESS_MSG = "Success! Transaction has been submitted to the network. Please wait for confirmation on the blockchain.";
  const cardsRequiredToCraft = 10;

  async function redeemLottoCard() {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncRewards, abis.lyncRewards, provider);
    let signed = await contract.connect(signer);
    let redeemLottoReward_result;
    try {
      await signed.redeemLotto(cardID);
      redeemLottoReward_result = SUCCESS_MSG;
    } catch (error) {
      redeemLottoReward_result = error;
    }
    alert(redeemLottoReward_result);
    return redeemLottoReward_result;
  };

  async function redeemCard() {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncRewards, abis.lyncRewards, provider);
    let signed = await contract.connect(signer);
    let redeemReward_result;
    try {
      await signed.redeemReward(cardID);
      redeemReward_result = SUCCESS_MSG;
    } catch (error) {
      redeemReward_result = error;
    }
    alert(redeemReward_result);
    return redeemReward_result;
  };

  async function boostCard() {
    if(cardToBoost !== "") {
        let signer = provider.getSigner();
        let contract = new Contract(addresses.lyncRewards, abis.lyncRewards, provider);
        let signed = await contract.connect(signer);
        let redeemBoost_result;
        try {
          await signed.redeemBoosterCard(cardID, cardToBoost);
          redeemBoost_result = SUCCESS_MSG;
        } catch (error) {
          redeemBoost_result = error;
        }
        alert(redeemBoost_result);
        return redeemBoost_result;
    } else {
      alert("Enter the card ID you wish to boost");
    }
  };

  return (
    <>
      {(cardData[1] === 1) &&
        <>
          {(cardID > 4) ?
            <Button variant="success" onClick={redeemCard}>REDEEM REWARD</Button> :
            <>
              {(cardID === 2 || cardID === 4) ?
                <Button variant="danger" onClick={redeemLottoCard}>CRAFT REWARD</Button> :
                <>
                  {(cardData[9] >= cardsRequiredToCraft) ?
                    <Button variant="danger" onClick={redeemLottoCard}>CRAFT REWARD</Button>
                  : <Button variant="secondary" disabled>{cardData[9]} of {cardsRequiredToCraft} CARDS</Button>
                  }
                </>
              }
            </>
          }
        </>
      }

      {(cardData[1] === 2) &&
        <>
          {(cardData[6] === "redeemTimerAvailable" && cardData[4] > 0) ?
            <Button variant="success" onClick={redeemCard}>REDEEM REWARD</Button>
          : <Button variant="secondary" disabled>REDEEM REWARD</Button>}
        </>
      }

      {(cardData[1] === 3) &&
        <Button variant="success" onClick={boostCard}>BOOST CARD</Button>
      }
    </>
  )
}
