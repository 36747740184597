import React, { useState } from "react";
import Card from 'react-bootstrap/Card';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { Contract } from "@ethersproject/contracts";
import { addresses, abis } from "@project/contracts";

export default function CardBulk({ provider, signedInAddress }) {

  const SUCCESS_MSG = "Success! Bulk / Destructable Cards have been crafted and submitted to the network. Please wait for confirmation on the blockchain.";

  const [toAddress, setToAddress] = useState("0x0");
  const [amount, setAmount] = useState("0");
  const [tokenReward, setTokenReward] = useState("");
  const [percentageReward, setPercentageReward] = useState("");

  async function craftBulkCard(provider, toAddress, amount, tokenReward, percentageReward) {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let signed = await contract.connect(signer);
    let bulkCraft_result;
    try {
      await signed.craftBulkCard(toAddress, amount, tokenReward, percentageReward);
      bulkCraft_result = SUCCESS_MSG;
    } catch (error) {
      bulkCraft_result = error;
    }
    alert(bulkCraft_result);
    return bulkCraft_result;
  }

  function craftCards() {
    if(amount === "0") {
      alert("You cannot craft zero cards!")
    } else if(tokenReward === "") {
      alert("Token Reward cannot be blank!")
    } else if(percentageReward === "") {
      alert("Percentage Reward cannot be blank!")
    } else {
      craftBulkCard(provider, toAddress, amount, tokenReward, percentageReward);
    }
  }

  return (
    <div className="crafter-single">
      <Card>
        <Card.Body>
          <Card.Title>Destructable / Bulk Crafter</Card.Title>
          <Card.Subtitle className="mb-2 text-muted">CREATE BULK CARDS</Card.Subtitle>
          <Card.Text className="card-crafter-description">Creates single use cards in bulk that burn once used. These cards have a single cardID for all cards and cannot be kept as collectables or boosted.</Card.Text>

          <Form>
            <Form.Group>
              <Form.Row>
                <Form.Label column="sm" lg={4}>Owner Address:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="0x0" onChange={e => setToAddress(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Amount to Craft:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="Total cards to craft" onChange={e => setAmount(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Token Reward:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 2500 (2500 tokens)" onChange={e => setTokenReward(e.target.value)} />
                </Col>
              </Form.Row>
              <br />
              <Form.Row>
                <Form.Label column="sm" lg={4}>Percent Reward:</Form.Label>
                <Col>
                  <Form.Control size="sm" type="text" placeholder="e.g. 10 (10% on stake)" onChange={e => setPercentageReward(e.target.value)} />
                </Col>
              </Form.Row>
            </Form.Group>

            <div className = "centered-submit-buttons">
              <Button variant="success" onClick={craftCards}>CRAFT BULK CARDS</Button>
            </div>

          </Form>

        </Card.Body>
      </Card>
    </div>
  )
}
