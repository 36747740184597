import React, { useState, useEffect } from "react";

import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { addresses, abis } from "@project/contracts";

import SingleNFT from "./singlenft";
import Spinner from 'react-bootstrap/Spinner';

export default function CardWallet({ provider, currentNetwork, correctNetwork, signedInAddress }) {

  const [rewardsBalance, setRewardsBalance] = useState("0");
  const [ownedCards, setOwnedCards] = useState("0");
  const [fetchingOwnedCards, setFetchingOwnedCards] = useState(false);
  const ownedCardObjects = ownedCards[0];

  async function fetchOwnedCards() {

    let contract = new Contract(addresses.lyncCrafter, abis.lyncCrafter, provider);
    let ownedCards_result = [];
    let totalCards;
    try {
      let fetchedTotalCards = await contract.cardID();
      totalCards = fetchedTotalCards.toNumber();

      for(let i = 0; i < totalCards; i++) {
        let fetchedCardBalance = await contract.balanceOf(signedInAddress, i);
        if(fetchedCardBalance > 0) {
          ownedCards_result.push({cardID:i});
        }
      }

      for(let i = 9949; i < 10000; i++) {
        let fetchedCardBalance = await contract.balanceOf(signedInAddress, i);
        if(fetchedCardBalance > 0) {
          ownedCards_result.push({cardID:i});
        }
      }

    } catch (error) {
      ownedCards_result = error;
    }
    setOwnedCards([ownedCards_result]);
    return ownedCards_result;
  };

  async function fetchRewardsBalance() {
    let contract = new Contract(addresses.lyncToken, abis.lyncToken, provider);
    let rewardsBalance_result;
    try {
      let fetchedBalance = await contract.balanceOf(addresses.lyncRewards);
      let convertBalance = ethers.utils.formatEther(fetchedBalance);
      let balance = parseFloat(convertBalance).toFixed();
      rewardsBalance_result = ethers.utils.commify(balance);
    } catch (error) {
      rewardsBalance_result = error;
    }
    setRewardsBalance(rewardsBalance_result);
  };

  useEffect(() => {
    if (provider && currentNetwork === correctNetwork) {
      fetchRewardsBalance();
      if (signedInAddress && !fetchingOwnedCards) {
        fetchOwnedCards();
        setFetchingOwnedCards(true);
      }
    }
  }, [provider, currentNetwork, correctNetwork, signedInAddress, fetchOwnedCards, fetchingOwnedCards, setFetchingOwnedCards, fetchRewardsBalance]);

  return (
    <div>
      {(currentNetwork === correctNetwork) ?
        <>
        <div className="nft-subheader">
        NFT Rewards Available: {rewardsBalance} LYNC
        {(ownedCards === "0") &&
            <Spinner animation="border" variant="success" />
        }
        </div>
          {(ownedCards !== "0") &&
            <>
              {(ownedCards[0].length === 0) ?
                <div className="nft-no-cards">
                  You do not own any cards
                </div> :
                <div>
                  {ownedCardObjects.map(card => (
                      <SingleNFT key={card.cardID} provider={provider} signedInAddress={signedInAddress} cardID={card.cardID} />
                  ))}
                </div>
              }
            </>
          }
        </> :
        <div className="nft-subheader">
            Connect Wallet and Check Network
        </div>
      }
    </div>
  );
}
