import React from "react";

export default function HomePage() {

  return (
    <div className="home">
    <h4>Welcome to the LYNC Network</h4>
    <p>The fundamental goal of the LYNC network is to create passive token rewards from the token flow mechanic that is central to the LYNC network. Token flow provides a regular supply of tokens back to the Reward Pool in the form of a minimal transaction fee and end-of-stake fees, this is then distributed to the community that stake the LYNC token.</p>
    <h5>Announcement and Community Channels</h5>
    <p>
      📢 <a href ="https://t.me/lyncnetworkannouncements" target="_blank" rel="noopener noreferrer">Official Telegram Announcements</a><br />
      💬 <a href ="https://t.me/lyncnetwork" target="_blank" rel="noopener noreferrer">Official Telegram Community</a><br />
      🐦 <a href ="https://twitter.com/thelyncnetwork?lang=en" target="_blank" rel="noopener noreferrer">Official Twitter</a><br />
    </p>

    <h5>NFT Related</h5>
    <p>
      ⛵ <a href ="https://opensea.io/collection/lync-network-craft-reward" target="_blank" rel="noopener noreferrer">LYNC Network Craft & Reward on OpenSea</a><br />
    </p>

    <h5>Exchange and Blockchain Tools</h5>
    <p>
      📈 <a href ="https://www.dextools.io/app/uniswap/pair-explorer/0xb4a7d044d7cf7117e9d106818526aebd9543a74a" target="_blank" rel="noopener noreferrer">dextools</a><br />
      🦄 <a href ="https://v2.info.uniswap.org/token/0x8f87ec6aad3b2a8c44f1298a1af56169b8e574cf" target="_blank" rel="noopener noreferrer">UNISWAP</a>
    </p>
    </div>
  )
}
