import React from "react";
import Button from 'react-bootstrap/Button';
import { Contract } from "@ethersproject/contracts";
import { ethers } from "ethers";
import { addresses, abis } from "@project/contracts";

export default function StakingApprove({ provider, signedInAddress, stakerApproved }) {

  const SUCCESS_MSG = "Success! Transaction has been submitted to the network. Please wait for confirmation on the blockchain.";

  const approve = "500000";
  const revoke = "0";

  async function approveRevoke(amount) {
    let signer = provider.getSigner();
    let contract = new Contract(addresses.lyncToken, abis.lyncToken, provider);
    let signed = await contract.connect(signer);

    const convertedAmount = ethers.utils.parseEther(amount);
    let approve_result;

    try {
      await signed.approve(addresses.lyncStaking, convertedAmount);
      approve_result = SUCCESS_MSG;
    } catch (error) {
      approve_result = error;
    }
    alert(approve_result);
    return approve_result;
  }

  function approveTokens() {
    approveRevoke(approve);
  }

  function revokeTokens() {
    approveRevoke(revoke);
  }

  return (
    <div className="staking-approval">
      {(stakerApproved > 0) ?
        <><div className="staking-approval-button"><Button variant="danger" onClick={revokeTokens}>REVOKE</Button></div><div className="staking-approval-text">Contract has been approved to Stake LYNC tokens</div></>
      : <><div className="staking-approval-button"><Button variant="success" onClick={approveTokens}>APPROVE</Button></div><div className="staking-approval-text">Contract must be APPROVED to stake LYNC tokens</div></>}
    </div>
  )
}
